import React, { useState } from 'react';
import { CODE_LENGTH } from './components/OtpInput/OtpInput.config';
import InitiateNewRegistration from './InitiateNewRegistration';
import { generateOtp, startProcess } from '../../services/apis';
import { useCustomContext } from '../../context/customContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../helpers/showToast';
import { useKeycloak } from '@react-keycloak/web';

const InitiateNewRegistrationContainer = ({ ...restProps }) => {
  const [code, setCode] = useState(
    Array.from(Array(CODE_LENGTH)).map(() => ''),
  );
  const [loading, setLoading] = useState(false);

  const { user } = useCustomContext();

  const { i18n, t } = useTranslation();
  const history = useNavigate();

  const { keycloak } = useKeycloak();

  const handleGenerate = (noOfOTPs) => {
    if (user) {
      setLoading(true);
      startProcess()
        .then(async () => {
          await generateOtp(user.tax_number, noOfOTPs)
            .then((resp) => {
              const otpArray = resp?.data?.map((otp) => otp.split(''));
              setCode(otpArray);
            })
            .catch((err) => {
              if (err?.status === 401) {
                keycloak.logout();
              }
              throw new Error(err.message);
            });
        })
        .catch((err) => {
          console.log({ err });
          showToast({
            data: {
              variant: 'error',
              text:
                err?.response?.message ||
                err.message ||
                t('common.general_error'),
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleHomeClick = () => {
    history('/' + i18n.language);
  };

  return (
    <InitiateNewRegistration
      onGenerate={handleGenerate}
      {...restProps}
      code={code}
      loading={loading}
      onHomeClick={handleHomeClick}
    />
  );
};

export default React.memo(InitiateNewRegistrationContainer);
