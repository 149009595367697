import React, { useState, useEffect } from 'react';
import Dashboard from './Dashboard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as api from '../../services/apis';
import { useCustomContext } from '../../context/customContext';
import { useKeycloak } from '@react-keycloak/web';

const DashBoardContainer = () => {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const [statistics, setStatistics] = useState({
    certifications: {
      active: 0,
      inProcess: 0,
      inActive: 0,
      expired: 0,
    },
    registrations: {
      active: 0,
      inProcess: 0,
      inActive: 0,
      expired: 0,
    },
    eInvoicing: {
      submitted: 0,
      rejected: 0,
    },
  });
  const [loading, setLoading] = useState(true);

  const { user } = useCustomContext();

  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (user) {
      api
        .getStatistics(user.tax_number)
        .then((resp) => {
          setStatistics(resp.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log({ err });

          if (err?.status === 401) {
            keycloak.logout();
          }
        });
    }
  }, [user]);

  const handleHomeClick = () => {
    history(`/${i18n.language}`, { replace: true });
  };

  return (
    <Dashboard
      loading={loading}
      onHomeClick={handleHomeClick}
      t={t}
      values={statistics}
    />
  );
};

export default React.memo(DashBoardContainer);
