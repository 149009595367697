import React, { useState } from 'react';
import SolutionsTable from './SolutionsTable';
import * as apis from '../../../../services/apis';
import { useCustomContext } from '../../../../context/customContext';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../../../helpers/showToast';
import { Modal } from '../../../../components/shared/Modal';
import { Button } from '../../../../components/shared/Button';
import styles from './SolutionsTable.styles.scss';
import classNames from 'classnames';

const SolutionsTableContainer = ({ ...props }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [serialToBeRevoked, setSerialToBeRevoked] = useState(null);

  const { user } = useCustomContext();
  const { t } = useTranslation();

  const revokeCertificate = () => {
    apis
      .revokeCertificate(serialToBeRevoked)
      .then(() => {
        if (user) {
          props.getSolutions(user.tax_number);
          showToast({
            data: {
              variant: 'info',
              text: t('revoked_successfully'),
            },
          });
        }
      })
      .catch((e) => {
        console.log(e);
        showToast({
          data: {
            variant: 'error',
            text: t('revocation_failed'),
          },
        });
      })
      .finally(() => {
        setModalOpen(false);
        setSerialToBeRevoked(null);
      });
  };

  const onRevokeClick = (serialNumber) => {
    setModalOpen(true);
    setSerialToBeRevoked(serialNumber);
  };

  const onNoClick = () => {
    setModalOpen(false);
    setSerialToBeRevoked(null);
  };

  return (
    <>
      <SolutionsTable onRevokeClick={onRevokeClick} {...props} />
      <Modal open={modalOpen} withCloseIcon={true} onClose={() => onNoClick()}>
        <p>{t('solutionList.revoke_confirmation')}</p>
        <div
          className={classNames('row mt-5', styles['modal-buttons-container'])}
        >
          <Button onClick={() => revokeCertificate()} className={'col-4'}>
            {t('common.yes')}
          </Button>
          <Button
            style={{ backgroundColor: '#BC0404' }}
            className={'col-4'}
            onClick={() => onNoClick()}
          >
            {t('common.no')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(SolutionsTableContainer);
